<template>
  <div id="RESOURCE_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Resource"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`staff.field.resources`) }}</label>
        <button class="btn-action" @click="resourceSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="resources">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="resourceBadgeRemove(index)" @badgeClick="resourceBadgeClick(item.uuId)"
              :text="`${item.name} (${item.unit > 1? item.unit + ' x ' :''}${item.utilization? item.utilization * 100 : 100}%)`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="resourceSelectorShow"
      :show.sync="resourceSelectorShow" 
      :entityService="resourceUtil"
      entity="RESOURCE"
      nonAdmin
      @cancel="resourceSelectorCancel"
      @ok="resourceSelectorOk"
    />
    <ResourceUnitModal :show.sync="resourceUnitEditShow" 
      :uuId="resourceUnitEdit.uuId" 
      :name="resourceUnitEdit.name" 
      :unit="resourceUnitEdit.unit" 
      :utilization="parseFloat(resourceUnitEdit.utilization)" 
      :cData="resourceUnitEdit"
      :edgeName="edgeName"
      @ok="resourceUnitOk"/>
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal :key="`resource-${index}`" 
        :show.sync="item.show" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="parseInt(item.unit)" 
        :utilization="parseFloat(item.utilization)"
        :cData="item"
        :edgeName="edgeName" 
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel" 
        canApplyAll />
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { objectClone } from '@/helpers'
import { getCustomFieldInfo } from '@/helpers/custom-fields';
import { resourceUtil } from '@/views/management/script/resource';
export default {
  name: 'ResourceBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    show: { type: Boolean, required: true }
    , resourceList: { type: Array, default: () => [] }
    , edgeName: {
      type: String
      , default: 'STAFF-RESOURCE'
      , validator(value) {
        return value == null || value == 'STAFF-RESOURCE' || value == 'TASK-RESOURCE'
      }
    }
  },
  data() {
    return {
      modalShow: false
      , resourceSelectorShow: false
      , resources: []
      , toConfirmResources: []
      , resourceUnitEditShow: false
      , resourceUnitEdit: {
        uuId: null
        , name: null
        , unit: null
        , utilization: null
      }
      , customFields: []
    }
  },
  created() {
    this.resourceUtil = resourceUtil
    this.modifiedList = []
  },
  beforeMount() {
    this.processWhenShow(this.show);
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.resourceUtil = null
  },
  computed: {
    customFieldKeys() {
      return this.customFields.map(i => i.name);
    }
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.processWhenShow(newValue);
      }
    }
  },
  methods: {
    async processWhenShow(newValue) {
      await getCustomFieldInfo(this, 'RESOURCE_LINK');
      if (this.modifiedList != null) {
        this.modifiedList.splice(0, this.modifiedList.length)
      }
      this.modalShow = newValue
      if (newValue) {
        this.resources.splice(0, this.resources.length, ...this.resourceList)
      }
    },
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , resourceSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.resourceSelectorShow = false
    }
    //Issue: Rapid click events will lead to the abrupt close of resourceBadgeGroupModal.
    //Solution: debounce is used to prevent that
    , resourceBadgeClick: debounce(function(id) {
      const selected = this.resources.find(i => i.uuId === id)
      const edit = {
        uuId: selected.uuId
        , name: selected.name
        , unit: selected.unit
        , utilization: selected.utilization
      }
      for (const k of this.customFieldKeys) {
        if (Object.hasOwn(selected, k)) {
          edit[k] = selected[k];
        }
      }
      this.resourceUnitEdit = edit
      this.resourceUnitEditShow = true
    }, 100)
    , resourceBadgeRemove: function(index) {
      this.resources.splice(index,1)
    }
    , resourceSelectorToggle() {
      this.resourceSelectorShow = true
    }
    , resourceSelectorOk({ details }) {
      const newResources = details.map(i => { return { uuId: i.uuId, name: i.name, unit: null, utilization: 0, show: false }});
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      for(let i = this.toConfirmResources.length-1; i > -1; i--) {
        this.toConfirmResources[i].toBeShown = true;
      }
      this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
      this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
    }
    , toConfirmResourceOk({ id, name, unit, utilization, oldId, applyToAll, customData }) {
      const oldIdIndex = oldId? this.toConfirmResources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected = this.toConfirmResources[oldIdIndex];
        selected.uuId = id;
        selected.name = name;
      } else {
        selected = this.toConfirmResources.find(i => i.uuId === id);
      }
      
      selected.unit = parseInt(unit);
      selected.utilization = parseFloat(utilization);
      for (const f of this.customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit();
      } 
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          for (const f of this.customFields) {
            if (Object.hasOwn(customData, f.name)) {
              entry[f.name] = customData[f.name];
            }
          }
        }
        this.toConfirmResourceCommit();
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    }
    , toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    }
    , toConfirmResourceCommit() {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const confirmedResources = this.toConfirmResources.filter(i => i.unit && i.unit > 0);
      const ids = confirmedResources.map(i => i.uuId);
      const currentIds = this.resources.map(i => i.uuId);
      const duplicatedIds = currentIds.filter(i => ids.includes(i));
      const newResources = confirmedResources.filter(i => !currentIds.includes(i.uuId));

      let resource = null;
      for(let i = 0, len = this.resources.length; i < len; i++) {
        resource = this.resources[i];
        if(duplicatedIds.includes(resource.uuId)) {
          const found = confirmedResources.find(i => i.uuId === resource.uuId);
          resource.unit = found.unit;
          resource.utilization = found.utilization;
          for (const f of this.customFields) {
            if (Object.hasOwn(found, f.name)) {
              resource[f.name] = found[f.name];
            }
          }
        }
      }

      newResources.forEach(i => {
        const r = {uuId: i.uuId, name: i.name, unit: i.unit, utilization: i.utilization}
        for (const f of this.customFields) {
          if (Object.hasOwn(i, f.name)) {
            r[f.name] = i[f.name];
          }
        }
        this.resources.push(r);
      });
    }
    , resourceUnitOk({ id, name, unit, utilization, oldId, customData }) {
      const oldIdIndex = oldId? this.resources.findIndex(i => i.uuId === oldId) : -1;
      let selected = null;
      if(oldIdIndex != -1) {
        selected= this.resources[oldIdIndex];
      } else {
        selected = this.resources.find(i => i.uuId === id);
      }
      selected.uuId = id;
      selected.name = name;
      selected.unit = unit;
      selected.utilization = utilization;
      for (const f of this.customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }
    }
    , ok() {
      this.$emit('ok',  { value: objectClone(this.resources), customFields: this.customFields })
      this.$emit('update:show', false)
    }
  }
}
</script>