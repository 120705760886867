<template>
  <div :id="componentId" style="height: 100%, width: 100%" >
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="['anti-shift', componentId]"
      @hidden="$emit('update:show', false)"
      scrollable
    >
      <AvatarBanner v-if="isAvatarBannerVisible" v-model="avatarBanner" :readOnly="isAvatarBannerReadOnly" :baseAvatarIcon="['fad', 'user-tie']" @status="avatarBannerStatus"/>
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>
      <span class="d-flex mr-1 date">
        <label class="mr-1 align-self-baseline" for="epochDate">{{ $t('epoch.date') }}</label>
        <b-form-datepicker id="epochDate" v-model="epochDate" class="date-picker d-flex" @input="epochChanged"
            today-button
            reset-button
            close-button
            hide-header
            :label-today-button="$t('date.today')"
            :label-reset-button="$t('date.reset')"
            :label-close-button="$t('date.close')"
            today-button-variant="primary"
            reset-button-variant="danger" 
            close-button-variant="secondary"
            size="sm"
          >
            <template v-slot:button-content="{ }">
              <font-awesome-icon :icon="['far', 'calendar-days']" />
            </template>
          </b-form-datepicker>
      </span>
      
      <b-form-group v-if="isEmailVisible" :label="$t('user.field.email')" label-for="email">
        <b-input-group>
          <b-form-input id="email" type="text"
            :data-vv-as="$t('user.field.email')"
            data-vv-name="user.email"
            data-vv-delay="500"
            v-model="user.email" 
            :readonly="true"
          />
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showEmailError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.email') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="isFirstNameVisible" :label="$t('user.field.firstName')" label-for="firstName">
        <b-input-group>
          <b-form-input id="firstName" type="text"
            :data-vv-as="$t('user.field.firstName')"
            data-vv-name="user.firstName"
            data-vv-delay="500"
            v-model="user.firstName" 
            :readonly="isFirstNameReadOnly" />
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFistNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.firstName') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="isLastNameVisible" :label="$t('user.field.lastName')" label-for="lastName">
        <b-input-group>
          <b-form-input id="lastName" type="text"
            :data-vv-as="$t('user.field.lastName')"
            data-vv-name="user.lastName"
            data-vv-delay="500"
            v-model="user.lastName" 
            :readonly="isLastNameReadOnly"
          />
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLastNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('user.lastName') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="isNickNameVisible" :label="$t('user.field.nickName')" label-for="nickName">
        <b-input-group>
          <b-form-input id="nickName" type="text"
            v-model="user.nickName" 
            :readonly="isNickNameReadOnly"/>
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="isMobileVisible" :label="$t('user.field.mobile')" label-for="mobile">
        <b-input-group>
          <b-form-input id="mobile" type="text"
            v-model="user.mobile"
            :readonly="isMobileReadOnly"/>
        </b-input-group>
      </b-form-group>

      <b-form-group :label="$t('language.title_selector')" label-for="language">
        <b-form-select id="language" v-model="language" :options="languages">
        </b-form-select>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button :disabled="!exists || waiting || user.ldapLogin" size="sm" variant="secondary" @click="sendMail" style="margin-right: auto">
          {{ $t('button.reset_password') }}
        </b-button>
        <b-button v-if="canEdit()" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>    
    
    <PasswordModal :register="false" :tokenId="tokenId" :show.sync="passwordShow" @success="passwordSuccess"/>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { cloneDeep } from 'lodash';
import { strRandom, EventBus } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { userService, authenticationService, languageProfileService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';

export default {
  name: 'UserModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    PasswordModal: () => import('@/components/modal/PasswordModal.vue')
  },
  props: {
    id:        { type: String,   default: `USER_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },

  },
  data() {
    return {
      permissionName: 'USER',
      alertMsg:        null,
      alertError:      true,
      alertMsgDetails: [],
      state: {
        editable:       false,
        isSubmitting:   false,
        modalShow:      false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      user: {
        uuId:      null,
        email:     null,
        firstName: null,
        lastName:  null,
        nickName:  null,
        mobile:    null,
        ldapLogin: null,
        ldapId: null
      },
      comments: [],
      waiting: false,
      tokenId: null,
      passwordShow: false,
      epochDate: null,
      languageProfile: {},
      languageList: [],
      language: 'en' // default to en
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.originEmail = null;
    this.originStaffEmail = null;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originEmail = null;
    this.originStaffEmail = null;
  },
  computed: {
    languages() {
      const avail = this.$root.$i18n.availableLocales;
      const options = [];
      for (const a of avail) {
        const found = this.languageList.find(l => l.locale === a);
        if (found) {
          options.push({ value: a, text: found.name });
        }
        else {
          // no name
          options.push({ value: a, text: this.getDefaultLangName(a) });
        }
      }
      return options;
    },
    componentId() {
      return `USER_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.$store.state.epoch.value !== null;
    },
    showError() {
      return this.alertMsg != null;
    },
    showEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'user.email');
    },
    showFistNameError() {
      return fieldValidateUtil.hasError(this.errors, 'user.firstName');
    },
    showLastNameError() {
      return fieldValidateUtil.hasError(this.errors, 'user.lastName');
    },
    exists() {
      return this.id && !this.id.startsWith('USER_NEW_');
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title : this.$t('user.title_new');
    },
    isAvatarBannerVisible() {
      return this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef']) && 
      ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists);
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']));
    },
    isEmailVisible() {
      //Email is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['email'])
    },
    // isEmailReadOnly() {
    //   return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['email']))
    // },
    isFirstNameVisible() {
      //FirstName is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['firstName'])
    },
    isFirstNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['firstName']))
    },
    isLastNameVisible() {
      //LastName is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['lastName'])
    },
    isLastNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['lastName']))
    },
    isNickNameVisible() {
      return this.canView(this.permissionName, ['nickName']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['nickName'])) || this.exists)
    },
    isNickNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['nickName']))
    },
    isMobileVisible() {
      return this.canView(this.permissionName, ['mobile']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['mobile'])) || this.exists)
    },
    isMobileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['mobile']))
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.alertError = true;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.resetUserProperties();
        
        // get the languages
        languageProfileService.listPublic(localStorage.companyId)
        .then(response => {
          const profile = response.data[response.data.jobCase];
          if (profile.length !== 0 && profile[0].languages) {
            this.languageList = profile[0].languages;
          }
          if (!this.languageList.find(r => r.locale === 'en-projectal')) {
            this.languageList.unshift({
              id: "0",
              name: "English (Default)",
              locale: "en-projectal",
              path: null
            });
          }
        })
        
        if(this.exists) {
          this.userGet(this.id);
          this.languageGet(this.id);
        }
      }
    }
  },
  methods: {
    epochChanged() {
      const epoch = moment(this.epochDate).unix() * 1000;
      this.$store.dispatch("epoch/update", epoch, { root: true });
    },
    getDefaultLangName(locale) {
      if (locale === 'en-projectal') {
        return 'English (Default)';
      }
      return locale;
    },
    async languageGet(id) {
      const languageProfile = await languageProfileService.list(id).then(response => {
        return response.data[response.data.jobCase];
      });
      
      if (languageProfile.length === 0) {
        await languageProfileService.create([{ language: this.$i18n.locale }], id).then((response) => {  
          const data = response.data[response.data.jobCase];
          this.languageProfile.uuId = data[0].uuId;
          this.languageProfile.language = this.$i18n.locale;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
        });
      }
      else {
        this.languageProfile = languageProfile[0];
        this.language = this.languageProfile.language;
      }
    },
    userGet(id) {
      userService.get([{ uuId: id}]).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      // Update the state variable with new details
      let user = this.$store.state.authentication.user;
      const u = this.user;
      for (const key of Object.keys(u)) {
        if (data[key]) { // property permissions may deny access to fields
          u[key] = data[key] || null;
          user[key] = data[key] || null;
        }
      }
      
      u.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = true;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);

      if(!this.user.email || this.user.email && this.user.email.trim().length < 1) {
        this.errors.add({
          field: 'user.email',
          msg: this.$t('validation.messages.required', [this.$t('user.field.email')])
        });
      }

      if(!this.user.firstName || this.user.firstName && this.user.firstName.trim().length < 1) {
        this.errors.add({
          field: 'user.firstName',
          msg: this.$t('validation.messages.required', [this.$t('user.field.firstName')])
        });
      }

      if(!this.user.lastName || this.user.lastName && this.user.lastName.trim().length < 1) {
        this.errors.add({
          field: 'user.lastName',
          msg: this.$t('validation.messages.required', [this.$t('user.field.lastName')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.userSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.scrollToTop();
        }
      });
      
    },
    userSubmit() {
      const data = cloneDeep(this.user);
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }
      let mode = 'update';
      if(!this.exists) {
        delete data['uuId'];
        mode = 'create';
      }
      this.userPost(mode, data);
    },
    async userPost(method, data) {
      this.state.isSubmitting = true;
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      let result = await this.updateUser(method, data);
      if(result.hasError) {
        this.alertMsg = result.msg;
        this.alertError = true;
        this.state.isSubmitting = false;
        return;
      }
      // const userId = result.userId;
      const alertDetails = [];

      this.state.isSubmitting = false;
      if(alertDetails.length > 0) {
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length, ...alertDetails);
        this.alertMsg = this.$t(`user.${method}_partial`);
        this.scrollToTop();
      } else {
        if (this.user.uuId === this.$store.state.authentication.user.uuId) {
          this.$set(this.$store.state.authentication.user, 'firstName', this.user.firstName);
          this.$set(this.$store.state.authentication.user, 'lastName', this.user.lastName);
          this.$set(this.$store.state.authentication.user, 'nickName', this.user.nickName);
          this.$set(this.$store.state.authentication.user, 'avatarRef', this.avatarBanner.avatarId);
          this.$set(this.$store.state.authentication.user, 'bannerRef', this.avatarBanner.bannerId);
        }
        // update the displayed language
        if (this.languageProfile.language !== this.language) {
          this.languageProfile.language = this.language;
          this.$i18n.locale = this.language;
          languageProfileService.update([this.languageProfile], this.user.uuId);
          EventBus.$emit('language-change');
        }
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`user.${method}`) });
      }
    },
    async updateUser(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`user.${method}`)
      }
      let userId = await userService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`user.error.failed_to_${method}_user`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasMissingArgument = false;
          let hasEdgeAlreadyExisted = false;
          for(let i = 0, len = list.length; i < len; i++) {
            if('Already_have_edge' === list[i].clue) {
              hasEdgeAlreadyExisted = true;
              break;
            }
            if('Missing_argument' === list[i].clue) {
              this.errors.add({
                field: `user.${list[i].spot}`,
                msg: this.$t('error.missing_argument', [this.$t(`user.field.${list[i].spot}`)])
              });
              hasMissingArgument = true;
            }
            else if('Not_unique_key' === list[i].clue) {
              this.errors.add({
                field: `user.${list[i].spot}`,
                msg: this.$t('user.error.duplicate_value', [this.$t(`user.field.${list[i].spot}`)])
              });
              hasMissingArgument = true;
            }
          }
          if(hasEdgeAlreadyExisted) {
            result.hasError = false;
            return data.uuId;
          } else if(hasMissingArgument) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.userId = userId;
      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        this.alertError = true;
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
          this.errors.add({
            field: `user.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
          this.alertError = true;
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
        this.alertError = true;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetUserProperties() {
      const keys = Object.keys(this.user);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        this.user[keys[i]] = null;
      }

      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;

      this.originEmail = null;
      this.originStaffEmail = null;
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
        this.alertError = true;
      }
    },
    sendMail() {
      this.waiting = true;
      const self = this;
      authenticationService.forgotPassword(this.user.email).then((response) => {
        const data = response.data;
        if (data.jobClue && data.jobClue.clue === 'SMTP_unavailable') {
          self.tokenId = data.jobClue.tokenId;
          self.passwordShow = true;
        }
        else {
          self.alertMsg = this.$t('forgot_password.success');
          self.alertError = false;
        }
        self.waiting = false;
      })
      .catch(() => {
        self.alertMsg = this.$t('forgot_password.failure');
        self.alertError = true;
        self.waiting = false;
      })
    },
    confirmEmailChangeOk() {
      this.ok();
    },
    commentSuccess() {
      this.userGet(this.id);
    },
    commentError({ msg }) {
      this.alertMsg = msg;
    },
    passwordSuccess() {
      this.$emit('update:show', false);
      this.$emit('success', { msg: !this.exists ? this.$t(`user.create`) : this.$t('user.password_updated') });
    }
  }
}
</script>
